import BasePlayScene from '../BaseFinder/PlayScene'

export default class PlayScene extends BasePlayScene {
  preload () {
    this.load.image('bg', '/static/games/game6/bg.jpg');
    this.load.audio('bg_audio', '/static/games/game6/bg.mp3');

    if (this.sys.game.device.os.desktop){
      this.load.spritesheet('bg_anim', '/static/games/game6/bg_anim.png',{ frameWidth: 1600, frameHeight: 900 });
    } else {
      this.load.image('bg_anim', '/static/games/game6/bg_noanim.png');
    }
    
    this.load.image('dropzone', '/static/games/game6/dropzone.png');
    this.load.audio('bg_audio', '/static/games/game6/bg.mp3');

    this.corrects = [
      { name:'1-star.png', x:882, y:528, drop_x:403, drop_y:775, width: 74.5, height: 89, drop_scale: 1,title:"ชุด Star" },
      { name:'2-brochure.png', x:1248, y:668, drop_x:682, drop_y:798, width: 289, height: 79, drop_scale: 0.70,title:"แผ่นพับความรู้" },
      { name:'3-bag.png', x:461, y:600, drop_x:913, drop_y:765, width: 172, height: 163, drop_scale: 0.65,title:"กระเป๋า" },
      { name:'4-document.png', x:947, y:440, drop_x:514, drop_y:775, width: 143, height: 88, drop_scale: 0.93,title:"เอกสารยืนยันตัวตน" }
    ]

    this.wrongs = [
      { name:'5-fakedocument.png', x:173, y:243, width: Math.floor(403/3), height: 147 },
      { name:'6-aroi.png', x:283, y:631, width: Math.floor(338/3), height: 133 },
      { name:'7-tash.png', x:683, y:598, width: Math.floor(358/3), height: 143 },
      { name:'8-fakebrochure.png', x:31, y:814, width: Math.floor(678/3), height: 80 },
      { name:'9-firstaid.png', x:1068, y:565, width: Math.floor(793/3), height: 138 },
      { name:'10-hamstercage.png', x:45, y:466, width: Math.floor(504/3), height: 117 },
    ]

    this.corrects.forEach((item,index)=>{
      this.load.spritesheet('correct-'+index, '/static/games/game6/objects/correct/'+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    this.wrongs.forEach((item,index)=>{
      this.load.spritesheet('wrong-'+index, '/static/games/game6/objects/wrong/'+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    super.preload();
  }

  init(data) {
    this.ended = false
    this.timer = 60000
    this.score = 0
    this.total_score = 4
    this.dropzone_w = 750
  }
}
