import Phaser from 'phaser'
export class PieMeter extends Phaser.GameObjects.Graphics {

  myRadius;
  direction = 0;

  // _scene:  the scene you want to display the meter in
  // _x, _y:  the position to display the meter
  // _radi:   the fadius of the meter
  // _dir:    the direction of the meter.  Value is either 1 or 0
  // _flip:   flips the meter horizontially and is used in conjunction with the _dir
  constructor(_scene, _x, _y, _radi, _dir, _flip) {
      super(_scene, { x: _x, y: _y });
      this.angle = 0;
      this.alpha = 0.8;
      this.scaleY = _flip;
      this.setActive(true);
      this.myRadius = _radi;
      this.direction = _dir;
      this.color = 0x78C862
      _scene.add.existing(this);
  }

  // requires a vaule between 0 amd 360
  drawPie(howMuch) {
      this.clear();
      this.fillStyle(this.color, 1);
      let radius = this.myRadius;

      // Rotate to make 0 as 12 o'clock
      this.angle = -90;
      if (this.direction == 0) {
          this.slice(0, 0, radius, 0, Phaser.Math.DegToRad(howMuch), true);
      } else {
          this.slice(0, 0, radius, Phaser.Math.DegToRad(howMuch), 0, true);
      }
      this.fillPath();
  }

  setColor(color) {
    this.color = color;
  }
}