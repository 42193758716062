import BaseScene from '../BaseScene';
import _ from 'lodash';
import { PieMeter } from '@/games/lib/pie-meter';


export default class BasePlayScene extends BaseScene {
  constructor () {
    super({ key: 'PlayScene' })

    this.dropzone_type = 'bottom';
  }

  preload () {
    this.load.audio('wrong_audio', '/static/games/base_finder/wrong.mp3');
    this.load.audio('correct_audio', '/static/games/base_finder/correct.mp3');
    this.load.audio('pick_audio', '/static/games/base_finder/pick.mp3');
    this.load.audio('warning_audio', '/static/games/base_finder/warning.mp3');
    this.load.audio('win_audio', '/static/games/base_finder/win.mp3');
    this.load.audio('lose_audio', '/static/games/base_finder/lose.mp3');

    this.load.svg('fullscreen', '/static/games/fullscreen.svg', {width:90, height:90});
    this.load.image('hint', '/static/games/base_finder/hint.png');
    this.loadingProgress();
  }
  
  create () {

    this.bg_audio = this.sound.add('bg_audio', {volume: 0.1});
    this.bg_audio.setLoop(true).play();

    var wrong_audio = this.sound.add('wrong_audio');
    var correct_audio = this.sound.add('correct_audio');
    var pick_audio = this.sound.add('pick_audio');
    this.warning_audio = this.sound.add('warning_audio', {volume: 0.3});
    this.warning_audio.setLoop(true)
    this.win_audio = this.sound.add('win_audio');
    this.lose_audio = this.sound.add('lose_audio');
    
    const bg = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'bg')
    if (this.sys.game.device.os.desktop){
      this.bg_anim = this.add.sprite(this.cameras.main.centerX, this.cameras.main.centerY, 'bg_anim')
      this.anims.create({
        key: 'play',
        frames: this.anims.generateFrameNumbers('bg_anim', { frames: [1,2,3,4,5,6,7,8,9,10,11,12,13,14] }),
        frameRate: 8,
        repeat: -1
      });
      this.bg_anim.play('play')
    } else {
      this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'bg_anim')
    }
    
    const dropzone = this.add.image(this.cameras.main.centerX-80, this.cameras.main.height-80, 'dropzone')
    const hint = this.add.image(this.cameras.main.centerX+(this.dropzone_w/2)+20, this.cameras.main.height-90, 'hint')
    if(this.dropzone_type==='aside') {
      dropzone.setOrigin(0)
      dropzone.x = this.cameras.main.width-this.dropzone_w
      dropzone.y = 0
      hint.x = (this.cameras.main.width-(this.dropzone_w/2))
    }
    const fullscreen = this.add.image(this.cameras.main.width-70, 70, 'fullscreen')


    this.item_wrongs = []
    this.wrongs.forEach((item,index)=>{
      let item_wrong = this.add.image(item.x,item.y, 'wrong-'+index)
      item_wrong.setOrigin(0)
      item_wrong.setInteractive({ draggable: true, useHandCursor: true });
      item_wrong.inputEnabled = true;
      item_wrong.is_correct = false;
      item_wrong.on('pointerover', (pointer)=>{item_wrong.setFrame(1)});
      item_wrong.on('pointerout', (pointer)=>{item_wrong.setFrame(0)});
      this.item_wrongs.push( item_wrong )
    })

    this.item_corrects = []
    this.corrects.forEach((item,index)=>{
      let item_correct = this.add.sprite(item.x,item.y, 'correct-'+index)
      item_correct.setOrigin(0)
      item_correct.setInteractive({ draggable: true, useHandCursor: true });
      item_correct.inputEnabled = true;
      item_correct.is_correct = true;
      item_correct.the_title = item.title;
      item_correct.droppoint = {drop_x:item.drop_x, drop_y:item.drop_y, drop_scale: item.drop_scale }
      item_correct.on('pointerover', (pointer)=>{if(item_correct.input.enabled)item_correct.setFrame(1)});
      item_correct.on('pointerout', (pointer)=>{if(item_correct.input.enabled)item_correct.setFrame(0)});
      this.item_corrects.push( item_correct )
    })
    
    this.input.on('dragstart', function (pointer, gameObject) {
      gameObject.setFrame(2)
      pick_audio.play()
    });
    this.input.on('drag', function (pointer, obj, dragX, dragY){ obj.setPosition(dragX, dragY); });
    this.input.on('dragend', function (pointer, gameObject, dropped) 
    {
      //console.log(gameObject.texture.key,gameObject.x,gameObject.y)
      if ( !gameObject.is_correct || !dropped )
      {
        wrong_audio.play()
        gameObject.x = gameObject.input.dragStartX;
        gameObject.y = gameObject.input.dragStartY;
        gameObject.setFrame(0)
      }
    });

    dropzone.setInteractive({ dropZone: true });
    this.input.on('drop',  (pointer, gameObject, dropZone) => {
      if(gameObject.is_correct)
      {
        gameObject.setFrame(2)
        gameObject.x = gameObject.droppoint.drop_x;
        gameObject.y = gameObject.droppoint.drop_y;
        gameObject.setScale(gameObject.droppoint.drop_scale)
        
        gameObject.removeAllListeners()
        correct_audio.play()
        
        this.showTitle(gameObject)
        setTimeout(()=>{
          this.objectTitle.visible = false
          gameObject.on('pointerover', () => {
            this.showTitle(gameObject)
          }).on('pointerout', () => {
            this.objectTitle.visible = false
          });
        },1000)
        

        this.score++
        if(this.score === this.total_score) {
          this.gameEnded()
        }
      }
    });

    fullscreen.setDepth(1);
    fullscreen.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
      this.scale.toggleFullscreen();
    });

    hint.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
      this.item_corrects.forEach((item)=>{item.setFrame(1)});
      setTimeout(()=>{
        this.item_corrects.forEach((item)=>{item.setFrame(0)});
      },500)

      this.item_wrongs.forEach((item)=>{item.setFrame(1)});
      setTimeout(()=>{
        this.item_wrongs.forEach((item)=>{item.setFrame(0)});
      },500)
    });
    
    this.timedEvent = this.time.delayedCall(this.timer, this.timeOut, [], this);

    var clock = this.add.graphics();
    clock.fillStyle(0xffffff, 1).fillCircle(70, 70, 50);
    clock.fillStyle(0xdddddd, 1).fillCircle(70, 70, 46);

    this.piemeter = new PieMeter(this, 70, 70, 46, 0, 1);

    var clock_pin = this.add.graphics();
    clock_pin.fillStyle(0xffffff, 1).fillCircle(70, 70, 36);

    this.timedText = this.add.text(70, 72, 'Timer', { fontFamily: 'WF Prompt', fontSize: 40, color: '#666666' }).setOrigin(0.5);

    this.objectTitle = this.add.text(100, 100, '', { fontFamily: 'WF Prompt', fontSize: 30, color: '#FFFFFF', backgroundColor:'#FC6800', padding: 10 });
    this.objectTitle.visible = false
    this.objectTitle.setOrigin(0)
  }

  timeOut () {

  }

  showTitle(gameObject) {
    this.objectTitle.setText(gameObject.the_title)
    this.objectTitle.x = gameObject.droppoint.drop_x - 50
    this.objectTitle.y = gameObject.droppoint.drop_y - 60
    this.objectTitle.visible = true
  }

  update () {
    let progress = this.timedEvent.getProgress()
    let timeLeft = ((this.timer - this.timedEvent.delay*progress)/1000).toFixed(0);
    if(progress === 1) {
      this.gameEnded()
    }
    if(this.ended === false && timeLeft < 10 && !this.warning_audio.isPlaying) {
      this.warning_audio.play()
      this.bg_audio.pause()
      this.timedText.setStyle({color: '#D55801'})
      this.piemeter.setColor(0xD55801)
    }
    //let seconds = Math.floor(timeLeft % 60);
    //let minutes = Math.floor(timeLeft / 60);
    //let timeFormatted = (this.zeroPad(minutes,2)+":"+this.zeroPad(seconds,2));
    this.timedText.setText(timeLeft);

    let pi = (progress*360).toFixed(0)
    this.piemeter.drawPie(pi);
  }

  gameEnded() {
    this.scene.pause();
    let isWin = this.score>=this.total_score;
    if(isWin) {
      this.win_audio.play()
    } else {
      this.lose_audio.play()
    }
    setTimeout(()=>{
      this.ended = true
      this.timedEvent.remove()
      this.warning_audio.pause()
      this.bg_audio.pause()
      this.scene.start('EndScene',{score:this.score, isWin:isWin})
    },600)
  }

  zeroPad(number,size){
    var stringNumber = String(number);
    while(stringNumber.length < (size || 2)){
      stringNumber = "0" + stringNumber;
    }
    return stringNumber;
  }
}
