import BaseBootScene from '../BaseFinder/BootScene'

export default class BootScene extends BaseBootScene {
  constructor () {
    super({ key: 'BootScene' })
  }

  preload() {
    this.load.image('startbg', '/static/games/game6/waitingbg.png');
    super.preload()
  }
  
  create () {
    this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'startbg')
    super.create()
  }
}
